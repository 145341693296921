const news = {
    2021: [
        {
            _id: "6557c9632869e53bb82dd5a5",
            title: "Awarded grant for NYC Media Lab’s inaugural AI + Local News Challenge (USD 7,500).",
            type: "awards",
            year: 2021,
            link: "",
            slides: "https://pitch.com/public/df64d867-2a3d-47fb-b255-5090ab97d25c",
            video: "https://youtu.be/4TZFoxoKlV8?t=2880",
            __v: 0,
        },
        {
            _id: "6557cd692869e53bb82dd5af",
            title: "Launched a pilot project with the Vermont Digger, tracking their online audiences on social media including Reddit and Twitter.",
            type: "projects",
            year: 2021,
            link: "https://vtdigger.org",
            slides: "https://simppl.org/audience-analytics/vtdigger_simppl_audience_analytics.pdf",
            video: "",
            __v: 0,
        },
        {
            _id: "6557cdb12869e53bb82dd5b1",
            title: "Invited Talk at Twitter Cortex.",
            type: "talks",
            year: 2021,
            link: "",
            slides: "https://drive.google.com/file/d/11OjCHg2NMo7UfqBAKUA1RPAu3K1yFbvl/view?usp=share_link",
            video: "",
            __v: 0,
        },
        {
            _id: "6557ce0e2869e53bb82dd5b4",
            title: "Invited Talk at Facebook Probability.",
            type: "talks",
            year: 2021,
            link: "",
            slides: "https://drive.google.com/file/d/11OjCHg2NMo7UfqBAKUA1RPAu3K1yFbvl/view?usp=sharing",
            video: "",
            __v: 0,
        },
    ],
    2022: [
        {
            _id: "6557d12f8539006ee952e459",
            title: "Invited Talk at Graphika.",
            type: "talks",
            year: 2022,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d15c8539006ee952e45b",
            title: "Identifying coordinated networks promoting articles from Russian state-backed media via Parrot Report supported by funding from The Sunday Times (UK) presented at the JournalismAI Festival.",
            type: "talks",
            year: 2022,
            link: "https://parrot.report",
            slides: "",
            video: "https://www.youtube.com/watch?v=FVetP1D5u0o",
            __v: 0,
        },
        {
            _id: "6557d1928539006ee952e45d",
            title: "Selected for News Product Alliance Mentorship Program by VP of Product Strategy at Dow Jones, Ms. Alyssa Zeisler.",
            type: "projects",
            year: 2022,
            link: "https://newsproduct.org/blog/npa-mentor-network-program-kicks-off",
            slides: "https://pitch.com/v/npa-artifact-z3it9c",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d1c08539006ee952e45f",
            title: "Launched a pilot with the Yale Daily News, the oldest, and one of the top student newspapers in the USA, on topics relating to Audience Analytics and Digital Strategy.",
            type: "projects",
            year: 2022,
            link: "https://yaledailynews.com/",
            slides: "https://docs.google.com/presentation/d/1nLmDVfndv72HZ8vPrdWyNNTaJQ12O1FOEJAONtUS_XU/edit?usp=sharing",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d1f38539006ee952e461",
            title: "Delivered a live demo (talk) of our system at Truth and Trust Online, Boston",
            type: "talks",
            year: 2022,
            link: "http://truthandtrustonline.com/",
            slides: "https://pitch.com/public/33f58cd9-d0d2-44bb-8c19-c0fd6c558d3d",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d2478539006ee952e463",
            title: "Invited talks at MIT, Stanford’s Trust and Safety Research Conference, AI4ABM workshop at the Intl. Conf. on Machine Learning, Misinfocon at DEFCON",
            type: "talks",
            year: 2022,
            link: "",
            slides: "https://mehtaver.se/oldtalks/slides/swapneel_icml_ai4abm_slides.pdf",
            video: "https://mit.zoom.us/rec/play/3myjxSAACcnuOeH8QyPM2Wei-_d74sjSOgisef9t-GuHaoFRRbZTC4mD4kuluoZtP4PTrNzst8Nbqqh4.WUNMjAn4RDJclPC1?continueMode=true&_x_zm_rtaid=mQEKnh9WSeGbukIUWiyapw.1662651817819.804fc0d205d4a8a4aef6f6c91d8f6982&_x_zm_rhtaid=670",
            __v: 0,
        },
        {
            _id: "6557d2798539006ee952e465",
            title: "Collaborated with Oxford University and NYU’s Center for Social Media and Politics to present early work on simulating bad behavior online and its impact on social network content recommendations.",
            type: "talks",
            year: 2022,
            link: "https://drive.google.com/file/d/1kcU13ajDYPrmZIdA5iafCpHye4MaSjcN/view?usp=sharing",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d2a88539006ee952e467",
            title: "Awarded Google Cloud (USD 4,700) and Amazon Web Services (USD 5000) Cloud Credit Grants.",
            type: "awards",
            year: 2022,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d2b88539006ee952e469",
            title: "Awarded NYU Tech Venture Workshop Grant (USD 1,000).",
            type: "awards",
            year: 2022,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d2c38539006ee952e46b",
            title: "Awarded Algovera AI Community Grants x 3 (USD 3,000).",
            type: "awards",
            year: 2022,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
    ],
    2023: [
        {
            _id: "6557c4856a6ff1480619b331",
            title: "Deploying a system for the Tech Global Institute to track gendered disinformation in Bangladesh, on Meta.",
            type: "projects",
            year: 2023,
            link: "https://techglobalinstitute.com/",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c4d46a6ff1480619b333",
            title: "Delivered a project with Deutsche Welle on newsroom strategies to deal with disinformation (via One Fact Foundation).",
            type: "projects",
            year: 2023,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c4f76a6ff1480619b335",
            title: "Delivered an invited talk on AI, Misinformation, and Journalism for the media development staff and partners at Deutsche Welle Akademie.",
            type: "projects",
            year: 2023,
            link: "",
            slides: "https://docs.google.com/presentation/d/1UHddAN2CzIJka1xPT3waf5Xa_VQpd42OKudJRzkGkp8/edit?usp=sharing",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c52b6a6ff1480619b337",
            title: "Awarded a Belfer Fellowship by the Anti-Defamation League to advance research into online harms against minorities",
            type: "awards",
            year: 2023,
            link: "https://www.adl.org/belfer-fellows",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c54a6a6ff1480619b339",
            title: "Awarded an exploreCSR award by Google, to advance student research in India.",
            type: "awards",
            year: 2023,
            link: "https://research.google/outreach/explore-csr/",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c5a96a6ff1480619b33c",
            title: "Awarded the AI2Amplify Hackathon prize, finalist for their startup award.",
            type: "awards",
            year: 2023,
            link: "https://www.goethe.de/prj/aia/en/a2a.html",
            slides: "https://drive.google.com/file/d/1G50crSzm8ojmtDMIHPoZgZhmwAhSWvrz/view?usp=sharing",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c66c6a6ff1480619b33e",
            title: "Selected to Future Founders - Startup Bootcamp.",
            type: "talks",
            year: 2023,
            link: "https://www.futurefounders.com/news-article/future-founders-announces-founders-joining-phase-2-of-startup-bootcamp-2023/",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c6fa6a6ff1480619b341",
            title: "Awarded Wikimedia grant funding to support the expansion of Parrot Report into Arbiter Report, including a study of more news providers.",
            type: "awards",
            year: 2023,
            link: "https://meta.m.wikimedia.org/wiki/WikiCred/2022_CFP/Audit_the_Impact_of_Credibility_in_Online_Information_Ecosystems",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c7246a6ff1480619b343",
            title: "Awarded follow-on funding from The Sunday Times and Ippen Media, via the JournalismAI Fellowship, to support our work identifying coordinated networks promoting articles from Russian state-backed media available online at Parrot Report.",
            type: "awards",
            year: 2023,
            link: "https://www.lse.ac.uk/media-and-communications/polis/JournalismAI",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c7796a6ff1480619b345",
            title: "Publishing a report on the disinformation landscape and implications for newsrooms, jointly with Deutsche Welle.",
            type: "projects",
            year: 2023,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c7b56a6ff1480619b347",
            title: "Published a report on the threat actors on Twitter.",
            type: "projects",
            year: 2023,
            link: "https://jhagrutlalwani.vercel.app/blog/network_analysis_simppl",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c7ed6a6ff1480619b349",
            title: "Invited Talk at mWise conference by Google Mandiant.",
            type: "talks",
            year: 2023,
            link: "",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557c8066a6ff1480619b34b",
            title: "Selected for the AI2Amplify Program by the Goethe Institute, funded by the German Ministry.",
            type: "talks",
            year: 2023,
            link: "https://www.goethe.de/prj/aia/en/a2a.html",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557cfc48539006ee952e450",
            title: "Swapneel selected as a Google Research Innovator.",
            type: "awards",
            year: 2023,
            link: "https://cloud.google.com/edu/researchers/innovators",
            slides: "",
            video: "",
            __v: 0,
        },
        {
            _id: "6557d09b8539006ee952e453",
            title: "Leading a Mercury-SSRC grant proposal (to be submitted May 2023) with partners at Columbia, MIT, Harvard, Stanford, and local nonprofits in India to address rural Indian vaccine hesitancy and misinformation.",
            type: "awards",
            year: 2023,
            link: "https://docs.google.com/document/d/1-AmBfMCYRMNmmDr5qi2cyuCLbbxuFAght7RoAg_xxlU/edit?usp=sharing",
            slides: "",
            video: "",
            __v: 0,
        },
    ],
};
export default news;
